
.backgroundImage {
  width: 100% !important;
  background-repeat: repeat-x;
  opacity: 0.1;
  // margin-bottom: 40px;
  // background-image: url($city-background-url);
  z-index: 0;
  top: calc(40% - 64px);
  // }
  height: 180px;
  width: -webkit-fill-available;
  // min-height: #{$bottom-background-height}px;
  position: absolute;
  // margin-top: #{$bottom-background-margin-top-on-large}px;

  // &.isDense {
  //   margin-top: #{$bottom-background-margin-top-on-dense}px;
  // }
}

.pageNotFoundContainer {
  background: rgba(239, 239, 239, 1);
  // height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    position: relative;
    z-index: 6;
    // height: calc(100% - 57px);

  }


}

.textContainer,
.bottomContainer {
  display: flex;
  flex-direction: column;
}

.notFoundImage {
  // height: 40%;
  // @media screen and (max-width: 1200px){
    pointer-events: none;
    
    height: 340px;
    
    // }
    top: calc(40% - 170px);
    position: absolute;


}

.bottomContainer {
  gap: 32px;
  align-items: center;
  position: absolute;
  top: calc(40% + 170px);

  // top: 497px;
}

.textContainer {
  gap: 8px;
  @media screen and (max-width: 1200px) {
  max-width: 358px;
  }
}

.notFoundTitle {
  color: rgba(0, 0, 0, 1);
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;

}

.notFoundSubTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;

  @media screen and (max-width: 1200px) {
    line-height: 24px;

  }

  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 1);

}

.notFoundTitle,
.notFoundSubTitle {
  text-align: center;
}

// // .largeSuggestionItemsList {
// //   grid-template-columns: repeat(auto-fill, minmax(178px, 50%));
// // }
// // .itemClassName {
// //   min-width: auto !important;
// // }
// // @media screen and (min-width: 720px) {

// // }

// // $padding-left-by-background-scrolling: var(--padding-left-by-background);
// // $margin-by-background-scrolling: var(--margin-by-background);
// // $margin-test: #{'-'}#{$test-meir}#{'px'};
// // $padding-test: $test-meir#{'px'};


// .banner {
//   min-height: max-content;

// }
// .isWithBackgroundImage{
//   background-color: transparent !important;
// }

// .horizontalCard {
//   min-width: 640px !important;
//   // min-width: 300px;
// }
// .desktopVerticalCard {
//   min-width: 448px;
// }
// .mobileVerticalCard {
//   min-width: 256px !important
// }

// .homePageSectionContainers {
//   display: grid !important;
//   gap: 32px;
//   overflow: visible !important;
//   // margin-top: -28px !important;
//   // padding-top: 32px;
//   // padding-bottom: 32px;
//   // @media (min-width: 768px) {
//   //   max-width: 560px;
//   // }

//   // @media (min-width: 1000px) {
//   //   max-width: calc(280px * 3);
//   // }

//   padding-top: 0px;
//   @media (min-width: 1200px) {
//     gap: 56px;
//     // max-width: calc(280px * 4);
//   }

//   // @media (min-width: 1500px) {
//   //   max-width: calc(280px * 5);
//   // }
// }

// // .horizontalCardImage {
// //   min-width: 288px !important;
// //   // min-width: 300px;
// // }

// .pageBodyGridContainerClassName {
//   // position: static;
//   // z-index: 2;
//   margin-top: -2px;
//   top: 430px;
//   &.isDense {
//     top: 505px;
//   }
// }

// // .firstScrollingSection {

// // }

// // .isFirst {
// //   z-index: 9;
// //   position: relative;

// //   @media screen and (min-width: 960px) {
// //     margin-top: -40px !important;
// //     // z-index: 9;
// //     // position: relative;
// //   }

// // }

// .test2 {
//   z-index: 0;
//   // position: relative;
// }

// .testClass {
//   z-index: 999;
//   // position: fixed;

// }

// .scrollingSectionWithBackground {
//   // margin-left: -200px;
//   // margin-left: $margin-by-background-scrolling !important;
//   // padding-left: $padding-left-by-background-scrolling !important;






//   // margin-left: calc(0 - $margin-left-in-scrolling)px !important;
//   // margin-left: -100px !important;
//   // background-position:center;
//   // background-size: cover;
//   // background-position: left !important;

//   // width: 100vw;
//   // grid-column: 1/7 !important;
//   // @media screen and (min-width: 960px) {
//   // background-position: right -250px;

//   // }



// }

// .generalScrollingSectionClass {
//   // width: auto;
//   // margin-right: -#{$test-meir}px;
//   // margin-right: $margin-by-background-scrolling !important;


//   // position: absolute;
//   // left: auto;
//   // background-color: #FFE4E1;

//   // right: auto;
//   // margin-left: -200px !important;
//   // width: 1848px !important;
// }

// // .cardsClass {

// // min-width: 1400px !important;
// // z-index: 999;
// .editOrderMessageCard {
//   @media screen and (max-width: 720px) {
//     margin-top: 16px;
//     min-height: 430px;
//   }

//   margin-top: -60px;
// }

// // }